var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "driverPendingBookingInfo",
      attrs: {
        id: "driverSignupDelivery",
        lazy: "",
        "hide-footer": "",
        size: "lg",
        title: _vm.modalTitle,
      },
      on: {
        show: _vm.handleOpen,
        hide: _vm.handleClose,
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "onDocsUpload",
            lazy: "",
            "no-fade": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            "header-bg-variant": "white",
            size: "md",
            "hide-footer": true,
            title: "Atualização da Data de Validade da CNH",
          },
        },
        [
          _c("p", [
            _vm._v(
              "Observamos uma divergência na data de validade em relação à imagem enviada. Por favor, confirme a data de validade da CNH:"
            ),
          ]),
          _c(
            "b-form",
            [
              _c(
                "label",
                {
                  staticClass: "font-weight-bold",
                  attrs: { for: "license_expired_at" },
                },
                [_vm._v("Data vencimento:")]
              ),
              _c("b-form-input", {
                attrs: { id: "license_expired_at", type: "date" },
                model: {
                  value: _vm.form.license_expired_at,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "license_expired_at", $$v)
                  },
                  expression: "form.license_expired_at",
                },
              }),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { block: "", variant: "warning" },
                  on: { click: _vm.changeCNHExpirationDate },
                },
                [_vm._v(" Confirmar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.driver_data
        ? _c("driver-header", {
            attrs: {
              id: _vm.driverId,
              modal: true,
              "new-schedule-modal": _vm.isNewScheduleModal,
            },
          })
        : _vm._e(),
      _vm.driver_data && _vm.driver_data.id && _vm.driverType === "NEW_DRIVER"
        ? [
            _c(
              "b-card",
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { md: "4" } }, [
                      _c("strong", [_vm._v(_vm._s(_vm.plan.name))]),
                      _c("br"),
                      _c("span", { staticClass: "small text-muted" }, [
                        _vm._v(_vm._s(_vm.fuel_names)),
                      ]),
                    ]),
                    _vm.pre_payment
                      ? _c("b-col", { attrs: { md: "4" } }, [
                          _c(
                            "p",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(" Pagamento caução "),
                              _vm.pre_payment.status
                                ? _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        variant: _vm._f(
                                          "transaction_status_variant"
                                        )(_vm.pre_payment.status),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("transaction_status")(
                                              _vm.pre_payment.status
                                            )
                                          ) +
                                          " "
                                      ),
                                      _vm.pre_payment.manual_confirmation
                                        ? [_vm._v(" (manual) ")]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "break-line" }, [
                            _vm._v(
                              "Valor: " +
                                _vm._s(
                                  _vm._f("money_intl")(
                                    _vm.pre_payment.amount_paid
                                  )
                                )
                            ),
                          ]),
                          _c("br"),
                          _vm.pre_payment.payment_method
                            ? _c("span", { staticClass: "small text-muted" }, [
                                _vm._v(
                                  " Pagamento com " +
                                    _vm._s(
                                      _vm._f("filterPaymentMethodType")(
                                        _vm.pre_payment.payment_method.type
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", { staticClass: "small text-danger" }, [
                                _vm._v("Aguardando forma de pagamento"),
                              ]),
                          _vm.pre_payment.payment_method.type === "boleto" &&
                          _vm.pre_payment.status !== "PAID" &&
                          _vm.pre_payment.manual_confirmation === false
                            ? _c(
                                "p",
                                { staticClass: "m-0 mt-2" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        href: `${_vm.invoice_url}${_vm.pre_payment.id}`,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip",
                                          },
                                        ],
                                        staticClass: "fa fa-link",
                                        attrs: { title: "Acessar fatura" },
                                      }),
                                      _vm._v(" Acessar fatura "),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.first_payment
                      ? _c("b-col", { attrs: { md: "4" } }, [
                          _c(
                            "p",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(" Pagamento 1º semana "),
                              _vm.first_payment.status
                                ? _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        variant: _vm._f(
                                          "transaction_status_variant"
                                        )(_vm.first_payment.status),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("transaction_status")(
                                              _vm.first_payment.status
                                            )
                                          ) +
                                          " "
                                      ),
                                      _vm.first_payment.manual_confirmation
                                        ? [_vm._v(" (manual) ")]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "break-line" }, [
                            _vm._v(
                              "Valor: " +
                                _vm._s(
                                  _vm._f("money_intl")(
                                    _vm.first_payment.amount_paid
                                  )
                                )
                            ),
                          ]),
                          _c("br"),
                          _vm.first_payment.payment_method
                            ? _c("span", { staticClass: "small text-muted" }, [
                                _vm._v(
                                  " Pagamento com " +
                                    _vm._s(
                                      _vm._f("filterPaymentMethodType")(
                                        _vm.first_payment.payment_method.type
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", { staticClass: "small text-danger" }, [
                                _vm._v("Aguardando forma de pagamento"),
                              ]),
                          _vm.first_payment.payment_method.type === "boleto" &&
                          _vm.first_payment.status !== "PAID" &&
                          _vm.first_payment.manual_confirmation === false
                            ? _c(
                                "p",
                                { staticClass: "m-0 mt-2" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        href: `${_vm.invoice_url}${_vm.first_payment.id}`,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip",
                                          },
                                        ],
                                        staticClass: "fa fa-link",
                                        attrs: { title: "Acessar fatura" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$i18n.t(
                                              "modalVehicleDelivery.texts.accessInvoice"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.hasBooking
              ? _c(
                  "b-card",
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "mt-sm-3 mt-md-0",
                            attrs: { md: "12" },
                          },
                          [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t("drivers.labels.reasonSchedule")
                                  )
                                ),
                              ]),
                            ]),
                            _c("p", [_vm._v(_vm._s(_vm.reasonSchedule))]),
                            _c("b-col"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.hasBooking
              ? _c(
                  "b-card",
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: _vm.showCarDocument ? "6" : "4" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  `${_vm.$t(
                                    "modalVehicleDelivery.texts.contractPhoto"
                                  )}:`
                                )
                              ),
                            ]),
                            _c("upload-image", {
                              attrs: {
                                "file-upload-props": {
                                  type: "contract",
                                  source: _vm.booking.id,
                                },
                                "auto-upload": "",
                                "help-text": _vm.$i18n.t(
                                  "modalVehicleDelivery.texts.phraseClickOnBox"
                                ),
                              },
                              model: {
                                value: _vm.contract_image,
                                callback: function ($$v) {
                                  _vm.contract_image = $$v
                                },
                                expression: "contract_image",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: _vm.showCarDocument ? "6" : "4" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  `${_vm.$t(
                                    "modalVehicleDelivery.texts.licenseNumberPhoto"
                                  )}:`
                                )
                              ),
                            ]),
                            _c("upload-image", {
                              attrs: {
                                "auto-upload": "",
                                "file-upload-endpoint": "/docs",
                                "file-upload-name": "data",
                                "file-upload-props": {
                                  documentType: "cnh_photo",
                                  driverId: _vm.driverId,
                                },
                                "help-text": _vm.$i18n.t(
                                  "modalVehicleDelivery.texts.phraseClickOnBox"
                                ),
                              },
                              on: {
                                result: function ($event) {
                                  return _vm.onResultUpload($event)
                                },
                              },
                              model: {
                                value: _vm.cnh_image,
                                callback: function ($$v) {
                                  _vm.cnh_image = $$v
                                },
                                expression: "cnh_image",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.showCarDocument
                          ? _c(
                              "b-col",
                              {
                                attrs: { md: _vm.showCarDocument ? "6" : "4" },
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      `${_vm.$t(
                                        "modalVehicleDelivery.texts.vehicleDocument"
                                      )}:`
                                    )
                                  ),
                                ]),
                                _c("upload-image", {
                                  attrs: {
                                    "file-upload-props": {
                                      type: "car_document",
                                      source: _vm.selected_car,
                                    },
                                    "auto-upload": "",
                                    "help-text": _vm.$i18n.t(
                                      "modalVehicleDelivery.texts.phraseClickOnBox"
                                    ),
                                  },
                                  model: {
                                    value: _vm.car_document_image,
                                    callback: function ($$v) {
                                      _vm.car_document_image = $$v
                                    },
                                    expression: "car_document_image",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          { attrs: { md: _vm.showCarDocument ? "6" : "4" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  `${_vm.$t(
                                    "modalVehicleDelivery.texts.proofAddressPhoto"
                                  )}:`
                                )
                              ),
                            ]),
                            _c("upload-image", {
                              attrs: {
                                "auto-upload": "",
                                "file-upload-endpoint": "/docs",
                                "file-upload-name": "data",
                                "file-upload-props": {
                                  documentType: "address_photo",
                                  driverId: _vm.driverId,
                                },
                                "help-text": _vm.$i18n.t(
                                  "modalVehicleDelivery.texts.phraseClickOnBox"
                                ),
                              },
                              on: {
                                result: function ($event) {
                                  return _vm.onResultUpload($event)
                                },
                              },
                              model: {
                                value: _vm.address_image,
                                callback: function ($$v) {
                                  _vm.address_image = $$v
                                },
                                expression: "address_image",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.booking
              ? _c(
                  "b-card",
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$i18n.t(
                                    "modalVehicleDelivery.texts.forecastDelivery"
                                  )
                                )
                              ),
                            ]),
                            _vm.currentDeliveryScheduleDate
                              ? _c("h5", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("format_date")(
                                          _vm.currentDeliveryScheduleDate,
                                          "DD/MM/YYYY HH:mm"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("h5", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "modalVehicleDelivery.texts.noShowDelivery"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                            _c(
                              "b-button",
                              {
                                staticClass: "w-100 white-color",
                                attrs: {
                                  id: "driver-view-link-generate-contract",
                                  variant: "warning",
                                  size: "lg",
                                },
                                on: { click: _vm.openModalRescheduleDelivery },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$i18n.t(
                                        "modalVehicleDelivery.texts.rescheduleDelivery"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("br"),
                            _c("p", { staticClass: "mt-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$i18n.t(
                                    "modalVehicleDelivery.texts.rescheduleNumber"
                                  )
                                ) +
                                  " " +
                                  _vm._s(_vm.scheduleCount)
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "mt-sm-3 mt-md-0",
                            attrs: { md: "4", sm: "12" },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.$i18n.t(
                                    "modalVehicleDelivery.texts.availableVehicles"
                                  ),
                                },
                              },
                              [
                                _c("car-available-select", {
                                  attrs: {
                                    "plan-id": _vm.driver_data.booking.plan.id,
                                  },
                                  model: {
                                    value: _vm.selected_car,
                                    callback: function ($$v) {
                                      _vm.selected_car = $$v
                                    },
                                    expression: "selected_car",
                                  },
                                }),
                                _vm.car
                                  ? _c(
                                      "b-form-group",
                                      {
                                        staticClass: "mt-3",
                                        attrs: {
                                          label: `${_vm.$i18n.t(
                                            "modalVehicleDelivery.texts.vehicleLinked"
                                          )}:`,
                                        },
                                      },
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "mb-0 font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.car.license_plate) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("p", [
                                          _c(
                                            "span",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.car.brand.name) +
                                                  " " +
                                                  _vm._s(_vm.car.model.name)
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "mt-sm-3 mt-md-0",
                            attrs: { md: "4", sm: "12" },
                          },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-100",
                                    attrs: {
                                      id: "driver-view-link-car-button",
                                      variant: "success",
                                      size: "lg",
                                      disabled:
                                        !_vm.selected_car ||
                                        _vm.isDriverLicenseExpired,
                                    },
                                    on: { click: _vm.$_confirmLinkCar },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$i18n.t(
                                            "modalVehicleDelivery.words.link"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm.car
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "mt-3 w-100",
                                        attrs: {
                                          id: "driver-view-link-generate-contract",
                                          variant: "primary",
                                          size: "lg",
                                        },
                                        on: { click: _vm.$_genContract },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$i18n.t(
                                                "modalVehicleDelivery.texts.printContract"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.car
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "mt-3 w-100 white-color",
                                        attrs: {
                                          id: "driver-view-link-generate-contract",
                                          variant: "warning",
                                          size: "lg",
                                        },
                                        on: { click: _vm.unlinkCar },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$i18n.t(
                                                "modalVehicleDelivery.texts.unlinkCar"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "text-center mb-4" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          id: "driver-view-cancel-booking",
                          variant: "danger",
                          disabled: !_vm.cancelButtonVisible,
                        },
                        on: { click: _vm.$_confirmCancelDelivery },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$i18n.t(
                                "modalVehicleDelivery.button.cancelDelivery"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { staticClass: "text-center mb-4" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          id: "driver-view-activate-booking",
                          variant: "success",
                          disabled: !_vm.can_activate_booking,
                        },
                        on: { click: _vm.$_confirmFinishBooking },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$i18n.t(
                                "modalVehicleDelivery.texts.finishDelivery"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.driver_data &&
          _vm.driver_data.id &&
          _vm.driverType !== "NEW_DRIVER"
        ? [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("b-card", [
                      _c(
                        "span",
                        { staticClass: "font-weight-bold text-muted" },
                        [_vm._v("Tipo de agendamento")]
                      ),
                      _c("br"),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("format_schedule")(
                              _vm.driver_data.type,
                              "type"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" >"),
                      _c("br"),
                      _c("br"),
                      _c(
                        "span",
                        { staticClass: "font-weight-bold text-muted" },
                        [_vm._v("Detalhamento")]
                      ),
                      _c("br"),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.driver_data.comments)),
                      ]),
                      _c("br"),
                      _c("br"),
                      _c(
                        "span",
                        { staticClass: "font-weight-bold text-muted" },
                        [_vm._v("Pátio")]
                      ),
                      _c("br"),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.completePlace)),
                      ]),
                      _c("br"),
                      _c("br"),
                      _c(
                        "span",
                        { staticClass: "font-weight-bold text-muted" },
                        [_vm._v("Data")]
                      ),
                      _c("br"),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("format_date")(
                              _vm.currentDeliverySchedule,
                              "DD/MM/YYYY - HH:mm"
                            )
                          )
                        ),
                      ]),
                      _c("br"),
                      _c("br"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "text-center", attrs: { md: "12" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "w-150 white-color",
                                attrs: {
                                  id: "driver-view-link-generate-contract",
                                  variant: "primary",
                                  size: "md",
                                },
                                on: { click: _vm.$_accesslocations },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$i18n.t(
                                        "modalVehicleDelivery.texts.accesslocations"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.shouldHideButtonBasedOnType
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "text-center mb-12 button-list" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "font-weight-bold",
                            attrs: {
                              id: "driver-view-activate-booking",
                              variant: "dark",
                              disabled: !_vm.can_activate_booking,
                            },
                            on: { click: _vm.$_confirmNotAttend },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$i18n.t(
                                    "modalVehicleDelivery.texts.notAttend"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "font-weight-bold",
                            attrs: {
                              id: "driver-view-activate-booking",
                              variant: "danger",
                              disabled: !_vm.can_activate_booking,
                            },
                            on: { click: _vm.$_confirmeCancelDelivery },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$i18n.t(
                                    "modalVehicleDelivery.texts.cancelDelivery"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              id: "driver-view-activate-booking",
                              variant: "success",
                              disabled: !_vm.can_activate_booking,
                            },
                            on: { click: _vm.$_confirmFinishDelivery },
                          },
                          [_vm._v(" " + _vm._s(_vm.newTextButton) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _c("modal-driver-signup-cancel-delivery", {
        attrs: {
          "modal-title": _vm.newModalCancelTitle,
          driver: _vm.driver_data,
          page: _vm.page,
        },
        on: {
          returnCancelDelivery: function ($event) {
            return _vm.$_cancelDelivery($event)
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }