<template>
  <multiselect
    label="text"
    placeholder="selecione um carro"
    track-by="value"
    :close-on-select="true"
    :disabled="!selectOptions.length || disabled"
    :multiple="false"
    :options="selectOptions"
    :value="findSelectedItem"
    @input="emitValue"
  />
</template>
<script>
import CARS_LIST_AVAILABLE from '@graphql/car/queries/list-available.gql';


export default {
  name: 'CarByPlanSelect',
  props: {
    value: {
      required: true,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    cars: [],
    loading: false,
  }),
  async beforeMount(){
    await this.fetchData()
  },
  computed: {
    findSelectedItem() {
      const selected = this.value;
      const available = this.selectOptions

      return selected && available.length
        ? available.find(item => item.value === selected)
        : null;
    },
    selectOptions() {
      return this.cars.map(car => ({
        value: car.id,
        text: `${car.license_plate} - ${car.brand?.name || ''} ${car.model?.name || ''}`,
      }));
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: CARS_LIST_AVAILABLE,
        });
        if (data.cars) {
          this.cars = data.cars.items;
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    },
    emitValue({ value }) {
      this.$emit('input', value);
    },
  },
};
</script>
