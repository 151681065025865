var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "driverPendingBookingInfo",
      attrs: {
        id: "driverSignupCancelDelivery",
        lazy: "",
        "hide-footer": "",
        size: "lg",
        title: _vm.modalTitle,
      },
      on: {
        show: _vm.handleOpen,
        hide: _vm.handleClose,
        close: _vm.handleClose,
      },
    },
    [
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { md: "12" } },
              [
                _c("p", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$i18n.t(
                        "modalVehicleDelivery.texts.explainReasonCancellation"
                      )
                    )
                  ),
                ]),
                _c("b-form-textarea", {
                  attrs: {
                    id: "textarea",
                    placeholder: _vm.$i18n.t(
                      "modalVehicleDelivery.texts.reasonCancellation"
                    ),
                    rows: "3",
                    "max-rows": "6",
                  },
                  model: {
                    value: _vm.cancelText,
                    callback: function ($$v) {
                      _vm.cancelText = $$v
                    },
                    expression: "cancelText",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { staticClass: "text-center mb-12 button-list" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "font-weight-bold btn-gray",
                    attrs: {
                      id: "driver-view-activate-booking",
                      variant: "dark",
                    },
                    on: { click: _vm.handleClose },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$i18n.t("modalVehicleDelivery.texts.buttonGoBack")
                      )
                    ),
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "font-weight-bold",
                    attrs: {
                      id: "driver-view-activate-booking",
                      variant: "danger",
                      disabled: !_vm.cancelText,
                    },
                    on: { click: _vm.$_cancelDelivery },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$i18n.t("modalVehicleDelivery.texts.cancelDelivery")
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }