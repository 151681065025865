<template>
  <b-modal
    id="driverSignupRescheduling"
    ref="driverPendingBookingInfo"
    lazy
    hide-footer
    size="lg"
    :title="$i18n.t('modalWaitingDelivery.texts.deliverySchedule')"
    @show="handleOpen"
    @hide="handleClose"
  >
    <driver-header :id="driver.id" :modal="true" />

    <rescheduling-module :driver="driver" @refreshDataTable="onRefreshDataTable" ></rescheduling-module>
</b-modal>
</template>

<script>
import DriverHeader from '@components/drivers/header'
import ReschedulingModule from '@components/drivers/signup/ReschedulingModule'

export default {
  name: 'ModalDriverSignupPayments',
  components: {
    DriverHeader,
    ReschedulingModule,
  },
  props: {
    driver: {
      type: Object,
      required: true
    },
    page: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleOpen() {
      this.window_closed = false
    },
    handleClose() {
      this.window_closed = true
    },
    onRefreshDataTable () {
      this.$root.$emit('bv::hide::modal', 'driverSignupDelivery')
      this.$emit('finished');
      if (!this.page) {
        this.$parent.$refs.drivers_datatable.$_refresh();
      }
    },
  }
};
</script>

<style></style>
