var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "upload-image" }, [
    !_vm.image && !_vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "upload-image__drag-area d-flex justify-content-center align-items-center",
            on: {
              click: _vm.$_onClick,
              drop: function ($event) {
                $event.preventDefault()
                return _vm.$_onDrop.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "upload-image__drag-area-text text-center" },
              [
                _c("i", {
                  staticClass: "fa fa-camera font-5xl text-secondary",
                }),
                _c("p", { staticClass: "m-0 mt-2" }, [
                  _vm._v(" " + _vm._s(_vm.label) + " "),
                ]),
              ]
            ),
          ]
        )
      : _c(
          "div",
          {
            staticClass:
              "d-block w-100 d-flex flex-wrap justify-content-center upload-image__preview",
            class: _vm.preview_modifiers,
          },
          [
            _c("img", {
              staticClass: "w-100",
              attrs: { src: _vm.image, alt: "image-preview" },
            }),
            _c(
              "b-button",
              {
                staticClass: "mt-3",
                attrs: { variant: "warning" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$_onClickResetImage.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$i18n.t("shared.words.remove")) + " ")]
            ),
          ],
          1
        ),
    !_vm.image
      ? _c(
          "div",
          { staticClass: "upload-image__help-text mt-2 small text-muted" },
          [_c("p", [_vm._v(_vm._s(_vm.helpText))])]
        )
      : _c(
          "div",
          { staticClass: "upload-image__help-text mt-2 small text-muted" },
          [
            _c("p", [
              _vm._v(" " + _vm._s(_vm.label) + " "),
              _c("a", { attrs: { href: _vm.image, target: "_blank" } }, [
                _c("i", { staticClass: "fa fa-external-link" }),
              ]),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }