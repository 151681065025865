<template>
  <b-modal
    id="driverSignupCancelDelivery"
    ref="driverPendingBookingInfo"
    lazy
    hide-footer
    size="lg"
    :title="modalTitle"
    @show="handleOpen"
    @hide="handleClose"
    @close="handleClose"
  >
    <template>
      <b-row>
        <b-col md="12">
          <p class="font-weight-bold">{{ $i18n.t('modalVehicleDelivery.texts.explainReasonCancellation') }}</p>
          <b-form-textarea
            id="textarea"
            v-model="cancelText"
            :placeholder="$i18n.t('modalVehicleDelivery.texts.reasonCancellation')"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-center mb-12 button-list">
          <b-button
            id="driver-view-activate-booking"
            class="font-weight-bold btn-gray"
            variant="dark"
            @click="handleClose"
          >{{ $i18n.t('modalVehicleDelivery.texts.buttonGoBack') }}</b-button>

          <b-button
            id="driver-view-activate-booking"
            class="font-weight-bold"
            variant="danger"
            :disabled="!cancelText"
            @click="$_cancelDelivery"
          >{{ $i18n.t('modalVehicleDelivery.texts.cancelDelivery') }}</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import CANCEL_SCHEDULE_DELIVERY from '@graphql/schedules/mutations/cancel-schedule-delivery.gql';
import CANCEL_SCHEDULE_DEVOLUTION from '@graphql/schedules/mutations/cancel-schedule-devolution.gql';
import CANCEL_SCHEDULE_INSPECTION from '@graphql/schedules/mutations/cancel-schedule-inspection.gql';

export default {
  name: 'ModalDriverSignupCancelDelivery',
  beforeCreate() {
    this.cancelText = ''
  },
  beforeDestroy() {
    this.$root.$emit('bv::hide::modal', 'driverSignupCancelDelivery')
  },
  components: {},
  props: {
    modalTitle: {
      type: String,
      default: ''
    },
    driver: {
      type: Object,
    },
    page: {
      type: String
    }
  },
  data: () => ({
    cancelText: '',
    cancel_schedule_delivery_mutation: CANCEL_SCHEDULE_DELIVERY,
    cancel_schedule_devolution_mutation: CANCEL_SCHEDULE_DEVOLUTION,
    cancel_schedule_inspection_mutation: CANCEL_SCHEDULE_INSPECTION
  }),
  computed: {
  },
  watch: {
    cancelTextProp(newValue) {
      this.cancelText = newValue
    }
  },
  methods: {
    handleClose() {
      this.$root.$emit('bv::hide::modal', 'driverSignupCancelDelivery');
    },
    handleOpen() {
      this.cancelText = '';
    },
    $_confirmCancelDelivery() {
      const currentMutation = this.page === "delivery" ? this.cancel_schedule_delivery_mutation : this.page === "devolution" ? this.cancel_schedule_devolution_mutation : this.cancel_schedule_inspection_mutation

      return this.$apollo
        .mutate({
          mutation: currentMutation,
          variables: {
            input: {
              id: this.driver.schedule,
              comments: this.cancelText
            }
          },
        })
        .then(() => {
          return true;
        })
        .catch(err => {
          return Promise.reject(
            this.$i18n.t('modalVehicleDelivery.texts.driverCancelError')
          );
        });
    },
    $_cancelDelivery() {
      const configSwal = {
        type: 'warning',
        title: this.modalTitle,
        text: this.$i18n.t(
          'modalVehicleDelivery.texts.cancelConfirmPhrase'
        ),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalVehicleDelivery.words.finish'),
        confirmButtonClass: 'driver-container-finish-booking-button',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalVehicleDelivery.words.close'),
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$_confirmCancelDelivery()
        },
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;
          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalVehicleDelivery.texts.driverCancelSuccess'),
            timer: 2000,
            text: '',
            showConfirmButton: false,
            showCancelButton: false
          };
          this.$swal(configSwalSuccess).then(val => {
            this.$emit('returnCancelDelivery', 'success');
            this.$root.$emit('bv::hide::modal', 'driverSignupCancelDelivery');
          });
        })
        .catch(err => {
          this.$log.logError(err);
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t(
              'modalVehicleDelivery.texts.driverCancelError'
            ),
            text: err,
            showConfirmButton: false,
            timer: 2000
          };
          this.$swal(configSwalFail)
            .then(() => {
              this.$emit('returnCancelDelivery', 'error')
            })
        });
    }
  }
};
</script>

<style scoped>
.button-list {
  margin-top: 20px;
}
/deep/ .button-list button:nth-child(2) {
  margin-left: 24px;
}
.btn-gray {
  color: #23282c;
  background-color: #c8ced3;
  border-color: #c8ced3;
}
.btn-gray:hover {
  color: #23282c;
  background-color: #acb5bc;
  border-color: #a5aeb7;
}
</style>
