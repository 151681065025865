<template>
  <section class="upload-image">
    <div
      v-if="!image && !loading"
      class="upload-image__drag-area d-flex justify-content-center align-items-center"
      @click="$_onClick"
      @drop.prevent="$_onDrop"
    >
      <div class="upload-image__drag-area-text text-center">
        <i class="fa fa-camera font-5xl text-secondary" />
        <p class="m-0 mt-2">
          {{ label }}
        </p>
      </div>
    </div>
    <div
      v-else
      class="d-block w-100 d-flex flex-wrap justify-content-center upload-image__preview"
      :class="preview_modifiers"
    >
      <img class="w-100" :src="image" alt="image-preview">
      <b-button
        variant="warning"
        class="mt-3"
        @click.prevent="$_onClickResetImage"
      >
        {{ $i18n.t('shared.words.remove') }}
      </b-button>
    </div>
    <div v-if="!image" class="upload-image__help-text mt-2 small text-muted">
      <p>{{ helpText }}</p>
    </div>
    <div v-else class="upload-image__help-text mt-2 small text-muted">
      <p>
        {{ label }}
        <a :href="image" target="_blank">
          <i class="fa fa-external-link" />
        </a>
      </p>
    </div>
  </section>
</template>

<script>
import { readFile, compressImage } from '@utils/image';
import NProgress from 'nprogress/nprogress';

const AVAILABLE_ENDPOINTS = ['/docs/upload-generic', '/docs'];

export default {
  name: 'UploadImage',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    autoUpload: {
      type: Boolean,
      default: false,
    },
    fileUploadProps: {
      type: Object,
      default: () => ({}),
    },
    fileUploadEndpoint: {
      type: String,
      default: '/docs/upload-generic',
      validator: value => AVAILABLE_ENDPOINTS.includes(value),
    },
    fileUploadName: {
      type: String,
      default: 'file',
    },
    label: {
      type: String,
      default: 'Enviar comprovante',
    },
    helpText: {
      type: String,
      default:
        'Envie o comprovante de pagamento, arraste o arquivo ou clique para fazer o upload dele.',
    },
    value: {
      type: [String, Object],
    },
  },
  data: () => ({
    image: null,
    loading: false,
  }),
  computed: {
    preview_modifiers() {
      return {
        'upload-image__preview--loading': this.loading,
      };
    },
    is_doc() {
      return this.fileUploadEndpoint === '/docs';
    },
  },
  mounted() {
    if (this.value) {
      this.image = this.value;
    }
  },
  methods: {
    async $_transformPdfToImage(pdfBase64File, currentProps) {
      const formData = new FormData();
      formData.append('data', pdfBase64File.split(',')[1]);
      formData.append('documentType', 'cnh_pdf');
      formData.append('driverId', currentProps.driverId || 'driverId');
      const res = await fetch(`${process.env.KOVI_API_URL}${this.fileUploadEndpoint}`, {
        method: 'POST',
        body: formData,
      })
      .then(response => {
        NProgress.done();
        return response.json();
      })

      return `data:image/png;base64,${res.image}`
    },

    async $_setImage(uploadedFile) {
      if (this.autoUpload) {
        this.loading = true;
      }

      try {
        let base64File = await readFile(uploadedFile);
        let base64FileOriginalPdf = null;
        let image = null
        //check if file is a pdf or image
        if (uploadedFile.type === 'application/pdf') {
          base64FileOriginalPdf = base64File;
          base64File = await this.$_transformPdfToImage(base64File, this.fileUploadProps)

          if(!base64File) {
            this.loading = false;
            return
          }
          image = base64File;
        } else {
          image = await compressImage(base64File);
        }
        this.image = image;

        if (this.autoUpload) {
          const fileData = await this.$_upload(image, {
            ...this.fileUploadProps,
            ...(base64FileOriginalPdf && { originalPdf: base64FileOriginalPdf }),
          });
          if (this.fileUploadProps.documentType === 'cnh_photo') this.$emit('result', fileData.body)

          if (fileData.status && fileData.status === 'success') {
            if (this.is_doc) {
              this.$emit('change', fileData.body);
            } else {
              this.$emit('change', fileData.body.absolute_path);
            }
          }

          this.loading = false;
        } else {
          this.image = image;
          this.$emit('change', this.image);
        }
      } catch (e) {
        this.$log.logError(e);
        throw new Error(e);
      }
    },
    async $_upload(file, fileProps = {}) {
      NProgress.inc();
      let formData = new FormData();
      formData.append(this.fileUploadName, file);

      for (let key in fileProps) {
        formData.append(key, fileProps[key]);
      }

      return fetch(`${process.env.KOVI_API_URL}${this.fileUploadEndpoint}`, {
        method: 'POST',
        body: formData,
      })
      .then(response => {
        NProgress.done();
        return response.json();
      })
    },
    $_onDrop(event) {
      let dataTransfer = event.dataTransfer;
      let files = dataTransfer.files;

      this.$_setImage(files[0]);
    },
    $_onClick() {
      const elFile = document.createElement('input');
      elFile.type = 'file';
      // accept image and pdfs
      if( this.fileUploadEndpoint === '/docs') {
        elFile.accept = '.jpg, .jpeg, .png, .gif, .bmp, .tif, .tiff, .ico, .jif, .jfif, .webp, .pdf';
      }else {
        elFile.accept = 'image/*';
      }
      elFile.click();

      elFile.onchange = () => {
        this.$_setImage(elFile.files[0]);
      };
    },
    $_onClickResetImage() {
      this.image = null;
      this.$emit('change', null);
    },
  },
};
</script>

<style scoped lang="scss">
.upload-image {
  width: 100%;
  max-width: 400px;

  &__drag-area {
    width: 100%;
    padding: 30px;
    cursor: pointer;
    border: 2px #a5a5a5 dashed;
  }

  &__preview {
    &--loading {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
