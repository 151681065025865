var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "driverPendingBookingInfo",
      attrs: {
        id: "driverSignupRescheduling",
        lazy: "",
        "hide-footer": "",
        size: "lg",
        title: _vm.$i18n.t("modalWaitingDelivery.texts.deliverySchedule"),
      },
      on: { show: _vm.handleOpen, hide: _vm.handleClose },
    },
    [
      _c("driver-header", { attrs: { id: _vm.driver.id, modal: true } }),
      _c("rescheduling-module", {
        attrs: { driver: _vm.driver },
        on: { refreshDataTable: _vm.onRefreshDataTable },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }