<template>
  <div class="wrapper">
    <b-col md="12" class="mt-sm-3 mt-md-0 card card-reset" style="padding:15px;">
      <select-places
        :place-title="$i18n.t('drivers.labels.placesDelivery')"
        :place-text="$i18n.t('drivers.labels.selectPlaces')"
        :type="{ delivery: true }"
        @eventPlaces="onEventPlaces($event)"
      />
    </b-col>a

    <b-card v-if="placeSelected">
      <b-row>
        <b-col>
          <span class="d-block w-100 text-center">
            {{ $i18n.t('modalWaitingDelivery.texts.selectEndDeliveryDate') }}
          </span>
        </b-col>
      </b-row>

      <b-row class="d-flex justify-content-center mt-3">
        <b-col md="3">
          <datepicker v-model="schedule_date" v-bind="date_picker_props" :lang="getLanguage" />
        </b-col>

        <b-col md="3">
          <b-button
            id="button_update_booking"
            :disabled="!is_valid_schedule_date"
            variant="success"
            @click="$_confirmSchedule"
          >
            {{ $i18n.t('modalWaitingDelivery.texts.rescheduleDelivery') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import RESCHEDULE_CAR from '@graphql/booking/mutations/reschedule-car.gql'
import RESCHEDULE_DELIVERY from '@graphql/schedules/mutations/create-reschedule-delivery.gql'
import SCHEDULE_DELIVERY from '@graphql/schedules/mutations/create-schedule-delivery.gql'
import { currentLanguage } from '@utils/language'
import SelectPlaces from '@components/shared/SelectPlaces'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleModule',

  components: {
    SelectPlaces,
  },

	props: {
		driver: {
			type: Object,
      required: true
		}
	},

	data () {
		return {
			schedule_date: null,
			schedule_mutation: RESCHEDULE_CAR,
      placeSelected: null
		}
	},

	computed: {
    ...mapGetters({
      currentSchedule: 'schedule/deliveryScheduleId',
    }),
    ...mapGetters('env', {
      envSchedules: 'schedules'
    }),

		getLanguage() {
      return currentLanguage
    },

		date_picker_props() {
      return {
        type: 'datetime',
        timePickerOptions: {
          start: '07:00',
          step: '00:15',
          end: '22:30'
        },
        shortcuts: false,
        clearable: false,
        editable: false,
        notBefore: this.today,
        notAfter: this.week,
        width: 'auto',
        format: 'DD/MM/YYYY HH:mm',
      }
    },

		is_valid_schedule_date() {
      return !!this.schedule_date && this.$moment(this.schedule_date).isValid(true)
    },

		today() {
      if (this.$moment().format('HH') > 18) { // If date > 18 send to another day
        return this.$moment().add(8, 'hours').format();
      }
      return this.$moment().format();
    },

    week() {
      return this.$moment().add(this.envSchedules.rescheduling, 'weeks').format();
    }
	},

	methods: {
		$_confirmSchedule() {
      const configSwal = {
        type: 'warning',
        title: this.$i18n.t('modalWaitingDelivery.texts.rescheduleDelivery'),
        text: this.$i18n.t('modalWaitingDelivery.texts.wishYouScheduleThisDeliveryTo', { date: this.$moment(this.schedule_date).format('DD/MM/YYYY [às] HH:mm').toString() }),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalWaitingDelivery.words.reschedule'),
        confirmButtonClass: 'delivery-modal;-schedule-car-button',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalWaitingDelivery.words.cancel'),
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          if (!this.is_valid_schedule_date) throw new Error(this.$i18n.t('modalWaitingDelivery.texts.invalidDate'));
          await this.$_rescheduleCar()
        }
      };

      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;

          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalWaitingDelivery.texts.deliveryWasReschedule'),
            timer: 2000,
            text: '',
            showConfirmButton: false,
            showCancelButton: false,
            onAfterClose: async () => {
              await this.$_refresh();
            },
          };

          this.$swal(configSwalSuccess)
        })
        .catch(err => {
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalWaitingDelivery.texts.couldNotPossibleRescheduleThisDelivery'),
            text: err,
            onAfterClose: async () => {
              await this.$_refresh();
            },
          };

          this.$swal(configSwalFail)
        });
    },

    async $_rescheduleCar() {
      return this.$apollo
			.mutate({
				mutation: RESCHEDULE_CAR,
				variables: {
					input: {
						booking: this.driver.booking.id,
						car_scheduled_at: this.schedule_date
					}
				}
      })
      .then(() => {
        if (this.currentSchedule) {
          this.$apollo
            .mutate({
              mutation: RESCHEDULE_DELIVERY,
              variables: {
                input: {
                  id: this.currentSchedule,
                  driver: this.driver.id,
                  scheduled_at: this.schedule_date,
                  place: this.placeSelected.value,
                  type: 'NEW_DRIVER',
                  metadata: {
                    conductor: 'DRIVER'
                  },
                  forceSchedule: true,
                }
              }
            })
        } else {
          this.$apollo
            .mutate({
              mutation: SCHEDULE_DELIVERY,
              variables: {
                input: {
                  driver: this.driver.id,
                  scheduled_at: this.schedule_date,
                  place: this.placeSelected.value,
                  type: 'NEW_DRIVER',
                  metadata: {
                    conductor: 'DRIVER'
                  },
                  forceSchedule: true,
                }
              }
            })
        }
      })
      .catch(e => {
        console.log(e)
      })
    },

		async $_refresh() {
      this.schedule_date = null;
      await this.$store.dispatch('driver/signupStatus');
      await this.$emit('refreshDataTable')
      await this.$root.$emit('bv::hide::modal', 'driverSignupRescheduling');
    },

    onEventPlaces($event){
      this.placeSelected = $event;
    }
	}
}
</script>

<style lang="scss" scoped>
.mx-input {
    cursor: default!important;
}
.mx-calendar-header {
    & > a.mx-icon-last-year,
    & > a.mx-icon-next-year {
        display: none!important;
    }
}
</style>
